<template>
	<div class="max-w-sm mx-auto">

		<div v-if="countryList">

			<table class="table-full">
				<thead>
					<tr class="border-b-2">
						<th class="p-1 border">Rank</th>
						<!-- <th>RankByTotal</th> -->
						<th class="p-1 border text-left" colspan="2">Country</th>
						<th class="p-1 border">🥇</th>
						<th class="p-1 border">🥈</th>
						<th class="p-1 border">🥉</th>
						<th class="p-1 border"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="country, u in countryList" :class="{ 'bg-gray-100': u % 2 == 0 }">
						<td class="text-center w-1/12 border p-1">{{ country.rank }}</td>
						<!-- <td>{{ country.rankByTotal }}</td> -->
						<td class="w-1/12 border p-1"><router-link :to="{ name: 'Medallists', params: { countryCode: country.countryCode } }">{{ country.countryCode }}</router-link></td>
						<td class="text-sm underline border p-1 truncate"><router-link :to="{ name: 'Medallists', params: { countryCode: country.countryCode } }">{{ country.country }}</router-link></td>
						<td class="w-1/12 text-center border p-1">{{ country.gold }}</td>
						<td class="w-1/12 text-center border p-1">{{ country.silver }}</td>
						<td class="w-1/12 text-center border p-1">{{ country.bronze }}</td>
						<td class="w-1/12 text-center border p-1 font-bold">{{ country.total }}</td>
					</tr>
				</tbody>
			</table>

		</div>

		<!-- <pre>{{httpResponses}}</pre> -->
	</div>
</template>

<script>

import _ from 'lodash';

export default {
	name: 'Medals',
	data: function() {
		return {
			httpResponses: {
				medals: false
			}
		}
	},
	methods: {
		fetchMedals: function() {
			this.$http.post(api_url + 'medals').then(function(data) {
				this.httpResponses.medals = data.data;
			});
		}
	},
	computed: {
		countryList: function() {
			if(!this.httpResponses.medals) return false;

			var sorted = _.map(this.httpResponses.medals, function(i) {
				i.rank = parseInt(i.rank);
				return i;
			});

			return _.orderBy(sorted, ['rank'], ['asc']);

			return sorted;
			console.log(this.httpResponses.medals);

			return this.httpResponses.medals;
		}
	},
	created: function() {
		this.fetchMedals();
	}
};

</script>