<template>
	<div class="about">
		<pre>{{httpResponses}}</pre>
	</div>
</template>

<script>

export default {
	name: 'Medals',
	data: function() {
		return {
			httpResponses: {
				athletes: false
			}
		}
	},
	methods: {
		fetchAthletes: function() {
			this.$http.post(api_url + 'athletes', { countryCode: this.$route.params.countryCode }).then(function(data) {
				this.httpResponses.athletes = data.data;
			});
		}
	},
	watch: {
		'$route.params.countryCode': {
			deep: true,
			immediate: true,
			handler: function() {
				this.fetchAthletes();
			}
		}
	}
};

</script>