<template>
	<div>
		<div v-if="medallistBySport">
			<div v-for="m, k in medallistBySport">
				{{k}}
				<ul>
					<li v-for="i in m"><pre>{{i}}</pre></li>
				</ul>
			</div>
			<!-- <pre>{{ medallistBySport }}</pre> -->
		</div>
	</div>
</template>

<script>

import _ from 'lodash';

export default {
	name: 'Medals',
	data: function() {
		return {
			httpResponses: {
				medallists: false
			}
		}
	},
	methods: {
		fetchMedallists: function() {
			this.$http.post(api_url + 'medallists', { countryCode: this.$route.params.countryCode }).then(function(data) {
				this.httpResponses.medallists = data.data;
			});
		}
	},
	computed: {

		medallistBySport: function() {

			if(this.httpResponses.medallists == false) return false;

			return _.groupBy(this.httpResponses.medallists, 'sportCode');

		}

	},
	watch: {
		'$route.params.countryCode': {
			deep: true,
			immediate: true,
			handler: function() {
				this.fetchMedallists();
			}
		}
	}
};

</script>